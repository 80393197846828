import { Nov1924 } from "./nov2024/11-19-24"
import { Nov524 } from "./nov2024/11-5-24"
import { Nov424 } from "./nov2024/11-4-24";
import { Nov224 } from "./nov2024/11-2-24";
import { Nov124 } from "./nov2024/11-1-24";
import { Oct3124 } from "./oct2024/10-31-24";
import { Oct3024 } from "./oct2024/10-30-24";
import { Oct2924 } from "./oct2024/10-29-24";
import { Oct2824 } from "./oct2024/10-28-24";
import { Oct2624 } from "./oct2024/10-26-24";
import { Oct2524 } from "./oct2024/10-25-24";
import { Oct2424 } from "./oct2024/10-24-24";
import { Jul2624 } from "./jul2024/7-26-24";
import { Jun2124 } from "./jun2024/6-21-24";
import { Feb1024 } from "./feb2024/2-10-24";
import { Jan0324 } from "./jan2024/1-3-24";
import { Nov1323 } from "./nov2023/11-13-23";
import { Nov1523 } from "./nov2023/11-15-23";
import { Nov1623 } from "./nov2023/11-16-23";
import { Nov1723 } from "./nov2023/11-17-23";
import { Nov2823 } from "./nov2023/11-28-23";
import { IPost } from "./types";

export const Posts: IPost[] = [
    Nov1924,
    Nov524,
  Nov424,
  Nov224,
  Nov124,
  Oct3124,
  Oct3024,
  Oct2924,
  Oct2824,
  Oct2624,
  Oct2524,
  Oct2424,
  Jul2624,
  Jun2124,
  Feb1024,
  Jan0324,
  Nov2823,
  Nov1723,
  Nov1623,
  Nov1523,
  Nov1323,
];
