/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Stack } from "@mui/material";
import { ImageCard } from "../../common/imageCard";
import { IPost } from "../types";

export let Nov1924: IPost = {
  id: 21,
  title: "Thoughts on Emptiness",
  date: new Date("2024/11/19"),
  tags: ["Longpost"],
  body: (
    <>
      <div>
        I have been thinking a lot about the Buddhist concept of Emptiness (pali
        word: Śūnyatā). This concept is important in Therevada Buddhism but it
        is the cornerstone of Zen Buddhism. Consider these passages from the
        <a href="https://www.zbtc.org/library/heart-sutra-english">
          Wisdom Heart Sutra
        </a>
      </div>
      <br></br>
      <blockquote>
        When Kannon, the Bodhisattva of Compassion, was practicing the deepest
        wisdom, he clearly saw that the five aggregates are empty thus
        transcending distress and suffering.
        <br></br>
        ...
        <br></br>
        Oh Shariputra, remember, Dharma is fundamentally emptiness, no birth, no
        death. Nothing is pure, nothing is defiled. Nothing can increase,
        nothing can decrease. Hence: in emptiness, no form, no feeling, no
        thought, no impulse, no consciousness; no eye, no ear, no nose, no
        tongue, no body, no mind; no seeing, no hearing, no smelling, no
        tasting, no touching, no thinking, no realm of sight, no realm of
        thought, no ignorance and no end of ignorance, no old age and death and
        no end to old age and death
      </blockquote>
      <br></br>
      <div>
        I thought it strange at first that the Bodhisattva of Compassion would
        place such emphasis on Emptiness. Surely it would make more sense for
        this being of pure compassion to emobdy something more warm and fuzzy,
        like loving-kindness. Emptiness seems like such a cold concept in
        contrast. It seems almost akin to dissociation. But as I have come to
        understand it, it is in fact the exact opposite of dissociation. Allow
        me to explain my understanding.
      </div>
      <Stack>
        <Box>
          <div>Consider this timelapse of a pepper.</div>
          <div>
            At the very beginning of the video, you might look at the pepper and
            say "oh, what a <b>delicious</b>-looking pepper" (if you like
            peppers, that is).
          </div>
          <div>
            But by the second day you might think "Oh, that pepper looks rather{" "}
            <b>unappetizing</b>".
          </div>
          <div>
            The pepper becomes less and less appealing until after a few days it
            becomes downright <b>revolting</b>.
          </div>
          <div>
            But if you wait even longer the pepper becomes soil, and then it is
            no longer disgusting anymore. You might feel totally <b>neutral</b>{" "}
            towards it
          </div>
          <div>
            But the key thing here is that none of these words (delicious,
            unappaetizing, revolting, neutral) are inherently properties of the
            pepper itself.
          </div>
          <div>
            It's important so I'll say it again: the pepper itself was never
            delicious nor revolting. These are properties that are tacked on to
            the pepper by the viewer's mind. In the absence of a witness to the
            pepper, it cannot be delicious or revolting. This applies to any
            property you would like to ascribe to the pepper at all: green,
            shiny, organic, alive. Even its identity as a 'pepper' is not a
            property of the pepper itself. It's just a bundle of atoms that the
            thinking mind refers to as a 'pepper'
          </div>
          <div>
            This becomes even more obvious if you think about what it would be
            like for a small child to encounter a pepper for the first time
            ever. They wouldn't have a word for it at all, perhaps only thinking
            about it as 'fruit' or 'food' or even 'ball'.
          </div>
          <div>
            If two beings can look at an object and draw completely different
            conclusions about it, then those conclusions necessarily cannot be a
            property of the object itself, but rather a property of the minds of
            the observers. The pepper itself is <b>empty</b> of these
            properties.
          </div>
          <div>
            This explanation is largely adapted from the book{" "}
            <i>Why Buddhism Is True</i> by Robert Wright
          </div>
        </Box>
        <iframe
          height="200"
          src="https://www.youtube.com/embed/VGB083d0_Zo?si=AUCVoH9ojtbRnZxc"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Stack>
      <div>That's all for now, planning on posting a pt 2 soon</div>
    </>
  ),
};
